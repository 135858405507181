import type { EventFormatted, IEventCreateBody } from '@pidk/api/src/types/api'
import { ProjectStatus } from '@pidk/api/src/types/api'
import { isDefined } from '@pidk/common/src/utils/isDefined'
import type { Project as IProject } from '@pidk/compose/src/types/compose'
import { Renderer } from '@pidk/renderer'
import type {
  OnEventCreate,
  OnNavigate,
  OnProjectLogin,
  OnRoomActivate,
  OnRoomClearLastInteraction,
  OnRoomCreate,
  OnRoomCreateInteraction,
  OnRoomDeactivate,
  OnRoomGetInteractionResults
} from '@pidk/renderer/src/contexts/renderer'
import useProjectAuthenticate from '@pidk/renderer/src/hooks/useProjectAuthenticate'
import useProjectFonts from '@pidk/renderer/src/hooks/useProjectFonts'
import { RendererView } from '@pidk/renderer/src/types/slide'
import * as Sentry from '@sentry/nextjs'
import Head from 'next/head'
import type { NextRouter } from 'next/router'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { createEvent } from '@/api/events'
import { loginProject } from '@/api/projects'
import {
  activateRoom,
  createRoom,
  createRoomInteraction,
  deactivateRoom,
  getRoomInteractionResults,
  roomClearLastInteraction
} from '@/api/rooms'
import { API_URL } from '@/config'
import { cacheMedia } from '@/utils/media'
import { setProjectCookie } from '@/utils/tokenStore'

export interface IProjectProps {
  isPreview: boolean
  project: IProject
}

interface IProjectState {
  currentDeckId: string
  currentSlideId: string
  view: RendererView
}

const getView = (router: NextRouter): RendererView => {
  if (!isDefined(router?.query?.view) && isDefined(router?.query?.deckId)) {
    return RendererView.DECK
  }

  if (!isDefined(router?.query?.view)) {
    return RendererView.OVERVIEW
  }

  return router!.query!.view as RendererView
}

const getInitialState = (router: NextRouter, isPreview: boolean, project: IProject): IProjectState => {
  const deckId = router.query.deckId as (string | undefined)
  const slideId = router.query.slideId as (string | undefined)
  const currentDeckId = deckId || project.decks[0].id

  return {
    currentDeckId,
    currentSlideId: slideId || project.decks.find(d => d.id === currentDeckId)?.slides?.[0]?.id,
    view: getView(router)
  }
}

const Project = ({
  isPreview,
  project
}: IProjectProps) => {
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(true)
  const projectAuthState = useProjectAuthenticate(project, isPreview)

  const [state, setState] = useState<IProjectState>(
    getInitialState(
      router,
      isPreview,
      project
    )
  )

  const handleNavigate: OnNavigate = async (view, deckId, slideId) => {
    setState(prevState => ({
      ...prevState,
      currentDeckId: deckId,
      currentSlideId: slideId,
      view
    }))

    // /:slug?deckId=x&slideId=y
    if (view === RendererView.DECK && (isDefined(deckId) && isDefined(slideId))) {
      delete router.query.view
      router.query.deckId = deckId
      router.query.slideId = slideId

      return router.push(router, undefined, {
        shallow: true
      })
    }

    // /:slug
    if (view === RendererView.OVERVIEW) {
      const root = isPreview
        ? `/preview/${project.id}`
        : project.slug

      return router.push(root, undefined, {
        shallow: true
      })
    }

    if (view !== undefined && view !== null) {
      router.query.view = view
    } else {
      delete router.query.view
    }

    if (deckId !== undefined && deckId !== null) {
      router.query.deckId = deckId
    } else {
      delete router.query.deckId
    }

    if (slideId !== undefined && slideId !== null) {
      router.query.slideId = slideId
    } else {
      delete router.query.slideId
    }

    await router.push(router, undefined, {
      shallow: true
    })
  }

  const projectFonts = useProjectFonts(project)

  useUpdateEffect(() => {
    setState(() => getInitialState(
      router,
      isPreview,
      project
    ))
  }, [
    router.query
  ])

  const handleError = (e: any) => {
    Sentry.captureException(e)
  }

  const handleRoomCreateInteraction: OnRoomCreateInteraction = async (hostSocketId: string, code: string, interactionType: string, interactionData: any) => {
    try {
      await createRoomInteraction(hostSocketId, code, interactionType, interactionData)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleRoomCreate: OnRoomCreate = async (hostSocketId, projectId, code) => {
    try {
      const res = await createRoom(hostSocketId, projectId, code)
      return res.code
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleRoomGetInteractionResults: OnRoomGetInteractionResults = async (hostSocketId: string, code: string, interactionId: string) => {
    try {
      return await getRoomInteractionResults(hostSocketId, code, interactionId)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleRoomDeactivate: OnRoomDeactivate = async (hostSocketId: string, code: string) => {
    try {
      await deactivateRoom(hostSocketId, code)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleRoomActivate: OnRoomActivate = async (hostSocketId: string, code: string) => {
    try {
      await activateRoom(hostSocketId, code)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleRoomClearLastInteraction: OnRoomClearLastInteraction = async (hostSocketId: string, code: string) => {
    try {
      await roomClearLastInteraction(hostSocketId, code)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  const handleProjectLoginSubmit: OnProjectLogin = async (values) => {
    const { projectToken } = await loginProject(project.id, values)

    setProjectCookie(projectToken)

    setState(prevState => ({
      ...prevState,
      view: (router.query.deckId ? RendererView.DECK : RendererView.OVERVIEW)
    }))
  }

  const handleEventCreate: OnEventCreate = async (body: IEventCreateBody): Promise<EventFormatted> => {
    // Don't submit the events in preview mode
    if (isPreview) {
      return
    }

    // Don't submit the events when project is not published
    if (project.status !== ProjectStatus.PUBLISHED) {
      return
    }

    // TODO: probably should also prevent submitting events when authenticated as admin in Production only

    try {
      return createEvent(body)
    } catch (e) {
      Sentry.captureException(e)
    }
  }

  React.useEffect(() => {
    cacheMedia(project.media).then().finally(() => {
      setIsLoading(false)
    })
  }, [project])

  if (!projectAuthState.isLoaded) {
    return
  }

  return (
    <>
      <Head>
        <title>
          {project.name} - Edustart
        </title>

        {projectFonts && (
          <link
            rel='stylesheet'
            href={projectFonts}
          />
        )}
      </Head>
      <Renderer
        isLoading={isLoading}
        isAuthenticated={projectAuthState.isAuthenticated}
        isPreview={isPreview}
        project={project}
        currentDeckId={state.currentDeckId}
        currentSlideId={state.currentSlideId}
        onNavigate={handleNavigate}
        view={state.view}
        isEditable={false}
        onError={handleError}
        socketUrl={API_URL}
        onProjectLogin={handleProjectLoginSubmit}
        onRoomCreate={handleRoomCreate}
        onRoomCreateInteraction={handleRoomCreateInteraction}
        onRoomGetInteractionResults={handleRoomGetInteractionResults}
        onRoomDeactivate={handleRoomDeactivate}
        onRoomActivate={handleRoomActivate}
        onRoomClearLastInteraction={handleRoomClearLastInteraction}
        onEventCreate={handleEventCreate}
      />
    </>
  )
}

export default Project
